import reducers from "./reducers";
import { createHashHistory } from 'history'
import {applyMiddleware, combineReducers, createStore} from 'redux';
import ReduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger'
import {  routerMiddleware } from 'react-router-redux'
import { connectRouter } from 'connected-react-router'


/** History and Middleware setup **/
export const history = createHashHistory();

let middleware = applyMiddleware(ReduxThunk, createLogger(), routerMiddleware(history));
const rootReducer = combineReducers(
    {
        ...reducers,
        router: connectRouter(history)
    }
)

export const store = createStore(
    connectRouter(history)(rootReducer),
    middleware
);
