import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/styles'
import {NavLink} from 'react-router-dom'
import classNames from 'classnames'
import {Tooltip} from "@material-ui/core";
import Zoom from '@material-ui/core/Zoom';

const style = theme => ({
    root: {},
    navLink: {
        color: theme.palette.grey[200],
        padding: theme.spacing(1),
        position: 'relative',
        display: 'block',
        '&:hover': {
            color: theme.palette.secondary[300]
        }
    },
    active: {
        color: theme.palette.primary[300],
    },
    navIcon: {
        width: '40px !important',
        height: 'auto !important'
    },
    disabledLink: {
        color: theme.palette.grey[600],
        pointerEvents: 'none'
    },
    tooltipPlacementRight: {
        margin: '0px'
    }
})

class SidebarContent extends React.Component {

    createNav = routes => {
        const {classes} = this.props
        let el = <nav>
            {
                routes.map((item, i) => {
                    if(false === item.locations.includes('sidebar')){ return ''}
                    return (
                        <Tooltip key={i} TransitionComponent={Zoom} title={item.label} placement="right">
                            <NavLink
                                className={classNames({
                                    [classes.navLink]: true,
                                    [classes.disabledLink]: item.disabled
                                })}
                                to={item.route}
                                activeClassName={classNames('menuBeak', classes.active)}

                            >
                                <item.icon className={classes.navIcon}/>
                            </NavLink>
                        </Tooltip>
                    )
                })
            }
        </nav>

        return el
    }

    render() {
        const {routes, classes} = this.props
        return (
            <div className={classes.root}>
                {this.createNav(routes)}
            </div>
        );
    }
}

SidebarContent.defaultProps = {
    routes: []
}

SidebarContent.propTypes = {
    routes: PropTypes.array.isRequired
}

export default withStyles(style)(SidebarContent)