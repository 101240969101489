
import appReducer from "./appReducer";
import usersReducer from '../modules/users/reducer'
import customersReducer from '../modules/customers/reducer'

const reducers = {
    app: appReducer,
    user: usersReducer,
    customer: customersReducer
}

export default reducers
