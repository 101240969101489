import PasswordValidator from 'password-validator'

const description = 'Must have minimum of 8 characters, 1 upper case, 1 lowercase, 1 digit'
let complexitySchema = new PasswordValidator()
complexitySchema.has().min(8)
complexitySchema.has().digits()
complexitySchema.has().uppercase()
complexitySchema.has().lowercase()

export {
    description,
    complexitySchema
}