import dashboardRoutes from './modules/dashboard/routes'
import customerRoutes from './modules/customers/routes'
import usersRoutes from './modules/users/routes'

let routes = []

const allRoutes = routes
    .concat(dashboardRoutes)
    .concat(customerRoutes)
    .concat(usersRoutes)


export default allRoutes