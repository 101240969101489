import {lazy} from 'react';
import LockIcon from "@material-ui/icons/Lock";
import PersonIcon from "@material-ui/icons/Person";

const AsyncPageUser = (
    lazy(() => (
        import('./PageUser')
    ))
)

const AsyncPageLogin = (
    lazy(() => (
        import('./PageLogin')
    ))
)

const routes = [
    {
        exact: true,
        route: '/',
        label: 'Sign Out',
        icon: LockIcon,
        locations: [
            'iconMenu'
        ],
        component: AsyncPageLogin
    },
    {
        exact: false,
        route: '/app/account',
        label: 'My Account',
        icon: PersonIcon,
        locations: [
            'iconMenu'
        ],
        component: AsyncPageUser
    }
]

export default routes