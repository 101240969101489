import {lazy} from 'react';
import GroupIcon from "@material-ui/icons/Group";

const AsyncPageCustomers = (
    lazy(() => (
        import('./PageCustomers')
    ))
)

const routes = [
    {
        exact: false,
        route: '/app/customers',
        label: 'Customers',
        icon: GroupIcon,
        locations: [
            'sidebar',
            'main'
        ],
        component: AsyncPageCustomers
    }
]

export default routes