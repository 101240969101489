import {lazy} from 'react';

import DashboardIcon from "@material-ui/icons/Dashboard";

const AsyncPageDashboard= (
    lazy(() => (
        import('./PageDashboard')
    ))
)

const routes = [
    {
        exact: false,
        route: '/app/dashboard',
        label: 'Dashboard',
        icon: DashboardIcon,
        locations: [
            'sidebar',
            'main'
        ],
        component : AsyncPageDashboard
    }
]

export default routes