import React from 'react';
import {store, history} from './store'
import {ConnectedRouter} from "connected-react-router";
import {Route, Switch} from "react-router";
import Login from "./modules/users/PageLogin";
import NotFound from "./layouts/NotFound";
import {Provider} from "react-redux";

/*** MUI **/
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import MainLayout from "./layouts/MainLayout";

/** MUI Theme **/
const theme = createMuiTheme({
        palette: {
            primary: blue,
            secondary: orange,
        },
    }
)

const App = () => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
                <Switch>
                    <Route path="/app" component={MainLayout}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/" exact component={Login}/>
                    <Route component={NotFound}/>
                </Switch>
            </ThemeProvider>
        </ConnectedRouter>
    </Provider>
)

export default App