import {actions} from '../actions/appActions'

let initialState = {
    loggingEnabled: true,

    applicationErrors: [],
    sessionLogs: {
        error: [],
        warning: [],
        success: []
    },

    routes: []
}

export default function reducer(state = initialState, action) {
    const {payload, type} = action
    let newRoutes, logType

    // eslint-disable-next-line
    switch (type) {

        /** ROUTES **/
        case actions.setDefaultRoutes:
            return {
                ...state, routes: payload
            }

        case actions.addRoute:
            newRoutes = [...state.routes, payload]
            return {
                ...state, routes: newRoutes
            }

        case actions.removeRoute:
            newRoutes = [...state.routes]
            newRoutes.splice(payload, 1)
            return {
                ...state, routes: newRoutes
            }

        case actions.enableRoute:
            newRoutes = [...state.routes]
            newRoutes[payload].disabled = false
            return {
                ...state, routes: newRoutes
            }

        case actions.disableRoute:
            newRoutes = [...state.routes]
            newRoutes[payload].disabled = true
            return {
                ...state, routes: newRoutes
            }

        /** LOGGING **/
        case actions.addLog:
            if (state.loggingEnabled) {
                let message = new Date().toLocaleString() + ' : ' + payload.message;
                logType = payload.type
                let logs = [...state.sessionLogs[logType], message]

                return {
                    ...state, sessionLogs: {...state.sessionLogs, [logType]: logs}
                }
            } else {
                return state
            }

        /** ERRORS **/
        case actions.addError:
            return {
                ...state
            }

        case actions.removeError:
            return {
                ...state
            }
    }
    return state;
}