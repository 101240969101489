import React from 'react'
import {withStyles} from "@material-ui/styles";
import {AppBar, Toolbar, Typography} from '@material-ui/core'
import UserMenu from "../../modules/users/UserMenu";
import HeaderMenu from "./HeaderMenu";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    leftCol: {},
    title: {
        display: 'inline-block'
    },
})

class HeaderContainer extends React.Component {


    render() {
        const {classes, routes} = this.props
        return (
            <div className={classes.root}>
                <AppBar position="static" color="inherit">
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            Plan A Manager:
                        </Typography>
                        <HeaderMenu routes={routes}/>
                        <div className={classes.grow}/>
                        <UserMenu routes={routes}/>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

export default withStyles(styles)(HeaderContainer)


