const axios = require('axios');

export class ApiService {

    init = (baseEndpoint, apiKey) =>{
        this.axiosInstance = axios.create({
            baseURL: baseEndpoint,
            withCredentials: false,
            headers: {
                common: {Authorization: apiKey}
            }
        })
        this.user_id = null
        this.token = null
    }

    setUserCredentials = (user_id, token) =>   {
        this.user_id = user_id
        this.token = token
    }

    /*
    Generic ApiService Call method
     */
    doPost = (action, paramObject) => {
        return new Promise((resolve, reject) => {
                if(this.user_id !== null){
                    paramObject = { ...paramObject, user_id: this.user_id, token: this.token }
                }
                this.axiosInstance.post(action, paramObject)
                    .then(function (response) {
                            resolve(response.data)
                        }
                    ).catch(function (error) {
                        let response

                        if (typeof error.response === 'undefined') {
                            response = {
                                data: {
                                    message: 'A network error occurred. This could be a CORS issue or a dropped internet connection. It is not possible for us to know.',
                                    status: 'warning'
                                }
                            };
                        } else {
                            response = error.response
                        }
                        reject(response)
                    }
                )
            }
        )
    }
}