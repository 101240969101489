import React, { Suspense} from 'react';
import {withStyles} from '@material-ui/styles'
import planaIcon from '../assets/img/SVG/pa-icon.svg'
import SidebarContent from "../components/sidebar/SidebarContent";
import defaultRoutes from '../routes'
import {connect} from 'react-redux'
import * as AppActions from '../actions/appActions'
import {Route, Switch} from "react-router";
import HeaderContainer from "../components/header/HeaderContainer";
import {LinearProgress} from "@material-ui/core";


const styles = theme => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '65px 1fr',
        gridTemplateRows: '50px 1fr 50px',
        gridTemplateAreas: `
            "sidenavtop header"
            "sidenav main"
            "sidenav footer"
            `,
        height: '100vh'
    },
    logo: {
        width: '40px',
        height: '40px',
        padding: '5px'
    },
    header: {
        gridArea: 'header',
        backgroundColor: theme.palette.common.white
    },
    sideNav: {
        gridArea: 'sidenav',
        backgroundColor: theme.palette.grey[800]
    },
    sideNavTop: {
        gridArea: 'sidenavtop',
        backgroundColor: theme.palette.grey[300]
    },
    main: {
        gridArea: 'main',
        backgroundColor: theme.palette.grey[100],
    },
    footer: {
        gridArea: 'footer',
        backgroundColor: theme.palette.grey[400]
    }
})


class MainLayout extends React.Component {

    componentDidMount() {
        this.props.dispatch(AppActions.setDefaultRoutes(defaultRoutes))

        /*
        TEST to see if this works
         */
        /*
        this.props.dispatch(AppActions.addRoute(
            {
              route: '/app/trials',
              label: 'Trials',
              icon: TimerIcon
            }
        ))
      */
        //this.props.dispatch( AppActions.enableRoute('/app/trials') )
        //this.props.dispatch( AppActions.disableRoute('/app/trials') )
    }

    renderRoutes = ()=>{
        const {routes} = this.props
        return routes.map((item,i)=>{
            return <Route key={i} path={item.route} component={item.component} exact={item.exact}/>
        })

    }

    render() {
        const {classes, routes} = this.props
        return (
            <div id="application-container" className={classes.root}>
                <header className={classes.header}><HeaderContainer routes={routes}/></header>
                <aside className={classes.sideNavTop}><img src={planaIcon} className={classes.logo} alt="logo"/></aside>
                <aside className={classes.sideNav}><SidebarContent routes={routes}/></aside>
                <main className={classes.main}>
                    <Suspense fallback={<LinearProgress/>}>
                        <Switch>
                            {this.renderRoutes()}
                        </Switch>
                    </Suspense>
                </main>
                <footer className={classes.footer}>Foot</footer>
            </div>
        );
    }
}

export default connect(
    state => {
        return {
            routes: state.app.routes
        }
    }
)(withStyles(styles)(MainLayout));
