import React from "react";
import loginBG from '../../assets/img/login-bg.jpg'
import {
    Dialog,
    DialogTitle,
    DialogContent
} from "@material-ui/core";
import {withStyles} from '@material-ui/styles'
import LockIcon from '@material-ui/icons/Lock'
import LoginForm from "./LoginForm";

const styles = theme => ({
    '@global': {
        body: {
            backgroundImage: "url(" + loginBG + ")"
        }
    },
    dialogTitle: {
        backgroundColor: theme.palette.secondary[500],
        color: theme.palette.common.white
    }
})

export class PageLogin extends React.Component {

    render() {
        const { classes } = this.props
        return (
            <Dialog open={true}>
                <DialogTitle className={classes.dialogTitle}><LockIcon/> Sign in</DialogTitle>
                <DialogContent>
                    <LoginForm />
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(PageLogin)