import React from "react";

export default class Login extends React.Component{

    render() {
        return (
            <div>
                Not Found
            </div>
        );
    }
}