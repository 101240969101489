import {apiInstance} from '../../services/index'
import {addLog} from '../../actions/appActions'
import CONSTANTS from '../../constants'

const prefix = 'CUSTOMER_ACTIONS_'

export const actions = {
    columnsFetch: prefix + 'columnsFetch',
    columnsFetchDone: prefix + 'columnsFetchDone',
    columnsFetchError: prefix + 'columnsFetchError',
    columnsFetchFinal: prefix + 'columnsFetchFinal',

    collectionFetch: prefix + 'collectionFetch',
    collectionFetchDone: prefix + 'collectionFetchDone',
    collectionFetchError: prefix + 'collectionFetchError',
    collectionFetchFinal: prefix + 'collectionFetchFinal',
}

export const columnsFetch = () => {
    return {type: actions.columnsFetch}
}

export const columnsFetchDone = data => {
    return {type: actions.columnsFetchDone, payload: data}
}

export const columnsFetchError = message => {
    return {type: actions.columnsFetchError, payload: message}
}

export const columnsFetchFinal = () => {
    return {type: actions.columnsFetchFinal}
}


export const collectionFetch = () => {
    return {type: actions.collectionFetch}
}

export const collectionFetchDone = data => {
    return {type: actions.collectionFetchDone, payload: data}
}

export const collectionFetchError = message => {
    return {type: actions.collectionFetchDone, payload: message}
}

export const collectionFetchFinal = () => {
    return {type: actions.collectionFetchFinal}
}


/*
NON REDUX, List only required locally
 */
export const getCollection = (params) => {
    const myAction = 'customer/get_collection';
    console.info('ACTION', myAction)

    return new Promise((resolve, reject) => {
            apiInstance.doPost(myAction, params)
                .then(response => {
                    resolve(response);
                })
                .catch( err => {
                    console.log(myAction + ' ERROR: ', err)
                })
        }
    )

}


