import * as ObjectUtils from '../lib/objectUtils'
import CONSTANTS from '../constants'
const prefix = 'APP_ACTIONS_'

export const actions = {

    setDefaultRoutes: prefix + 'setDefaultRoutes',
    addRoute: prefix + 'addRoute',
    removeRoute: prefix + 'removeRoute',
    enableRoute: prefix + 'enableRoute',
    disableRoute: prefix + 'disableRoute',

    addLog : prefix + 'addLog',
    addError : prefix + 'addError',
    removeError : prefix + 'removeError',
}

export const setDefaultRoutes = routes => {
    return { type: actions.setDefaultRoutes , payload: routes }
}

export const addRoute = route => {
    return { type: actions.addRoute , payload: route }
}

export const removeRoute = routePath => {
    return (dispatch, getState) => {
        const sidebarRoutes = getState().app.sidebarRoutes
        //find index
        let wantedIndex = ObjectUtils.arrayFindIndexWithObjectProperty(sidebarRoutes,"route", routePath)
        if(false === wantedIndex){
            dispatch(addLog(CONSTANTS.TYPE_WARN, 'removeRoute failed: route does not exist'))
        }else{
            dispatch({ type: actions.removeRoute , payload: wantedIndex })
            dispatch(addLog( CONSTANTS.TYPE_SUCCESS, 'removeRoute' + routePath))
        }
    }
}

export const enableRoute = routePath => {
    return (dispatch, getState) => {
        const sidebarRoutes = getState().app.sidebarRoutes
        //find index
        let wantedIndex = ObjectUtils.arrayFindIndexWithObjectProperty(sidebarRoutes,"route", routePath)
        if(false === wantedIndex){
            dispatch(addLog(CONSTANTS.TYPE_WARN, 'enableRoute failed: route does not exist'))
        }else{
            dispatch({ type: actions.enableRoute , payload: wantedIndex })
            dispatch(addLog( CONSTANTS.TYPE_SUCCESS, 'enableRoute' + routePath))
        }
    }
}

export const disableRoute = routePath => {
    return (dispatch, getState) => {
        const sidebarRoutes = getState().app.sidebarRoutes
        //find index
        let wantedIndex = ObjectUtils.arrayFindIndexWithObjectProperty(sidebarRoutes,"route", routePath)
        if(false === wantedIndex){
            dispatch(addLog(CONSTANTS.TYPE_WARN, 'disableRoute failed: route does not exist'))
        }else{
            dispatch({ type: actions.disableRoute , payload: wantedIndex })
            dispatch(addLog( CONSTANTS.TYPE_SUCCESS, 'disableRoute' + routePath))
        }
    }
}

export const addError = errorMessage => {
    return { type: actions.addError, payload: errorMessage }
}


export const addLog = (type, message) => {
    return { type: actions.addLog, payload: {type: type, message: message} }
}

