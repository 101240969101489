import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircleRounded';

import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton'
import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

function UserMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderRoutes = routes => {
        return routes.map((item, i) => {
            if( ! item.locations.includes('iconMenu')) { return ''}
            return <MenuItem key={i}  component={Link} to={item.route} onClick={handleClose}>
                <ListItemIcon>
                    <item.icon />
                </ListItemIcon>
                <ListItemText primary={item.label} />
            </MenuItem>
        })
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <AccountCircleIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: 200,
                    },
                }}
            >
                <MenuList >
                    { renderRoutes(props.routes)}
                </MenuList>
            </Menu>
        </div>
    );
}

export default connect()(UserMenu)