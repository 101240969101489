import { apiInstance } from '../../services/index'
import { addLog } from '../../actions/appActions'
import CONSTANTS from '../../constants'
import { push } from 'connected-react-router'

const prefix = 'USER_ACTIONS_'

export const actions = {
    signIn: prefix + 'signIn',
    signOut: prefix + 'signOut',
    signInSuccess: prefix + 'signInSuccess',
    fetch: prefix + 'fetch',
    fetchDone: prefix + 'fetchDone',

    signInError: prefix + 'signInError',
    profileView: prefix + 'profileView',
}

export const fetch = message => {
    return { type: actions.fetch }
}

export const fetchDone = message => {
    return { type: actions.fetchDone }
}


export const signIn = (email, password) => {
    return dispatch => {
        dispatch(fetch())
        apiInstance.doPost('user/sign_in', {email: email, password: password})
            .then(response => {
                dispatch(signInSuccess( response.data ))
                apiInstance.setUserCredentials(response.data.user.id, response.data.token)
                dispatch(push('/app/dashboard'))
            })
            .catch( err => {
                console.log('ERR', err)
                let statusText, logText;
                if(undefined !== err.data.error){
                    statusText = err.data.error.message
                    logText = err.data.error.payload
                }
                dispatch( addLog(CONSTANTS.TYPE_ERROR, 'user_sign_in - ' + logText))
                dispatch( signInError(statusText));
            })
            .finally(()=>{
                dispatch(fetchDone())
            })
    }
}

export const signInSuccess = user =>
{
    return { type: actions.signInSuccess,  payload: user }
}

export const signInError = message => {
    return { type: actions.signInError, payload: message }
}

export const profileView = () => {
    return dispatch => {
        dispatch(push('/app/customers'))
    }
}

