import React from "react";
import {connect} from 'react-redux'
import {signIn} from './actions'
import validator from 'validator';
import {complexitySchema} from '../../services'
import {
    TextField, Button, Snackbar, SnackbarContent, CircularProgress
} from "@material-ui/core";
import {withStyles} from '@material-ui/styles'

const styles = theme => ({
    errorContainer: {
        position: 'unset',
    },
    errorMessage: {
        backgroundColor: theme.palette.error.dark,
        width: '100%'
    }
})

export class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            fields: {
                email: {
                    errorText: '',
                    isValid: null,
                    value: null
                },

                password: {
                    errorText: '',
                    isValid: null,
                    value: null
                }
            }

        }
    }

    onChange = e => {
        const {target: currentTarget} = e
        const {id, value, dataset} = currentTarget
        this.validateField(id, dataset['type'], value)
        setTimeout(this.hasError, 300)
    }

    validateField = (id, type, value) => {
        let result, errorText = ''

        switch (type) {
            case 'email':
                result = validator.isEmail(value)
                if (!result) {
                    errorText = 'Email is invalid format'
                }
                break

            case 'password':
                result = complexitySchema.validate(value)
                if (!result) {
                    errorText = 'Password does not meet complexity'
                }
                break

            default:
                result = false
        }

        let newField = {
            errorText: errorText,
            isValid: result,
            value: value
        }
        let newState = {...this.state.fields, [id]: newField}
        this.setState(
            {fields: newState}
        )

        return result
    }

    onSubmit = e => {
        e.preventDefault()
        if (!this.state.hasError) {
            this.props.dispatch(signIn(this.state.fields.email.value, this.state.fields.password.value))
        } else {
            console.log('ERROR')
        }
    }

    hasError = () => {
        let hasError = false;
        Object.keys(this.state.fields).forEach(key => {
            if (!this.state.fields[key].isValid) {
                hasError = true
            }
        })
        this.setState({hasError: hasError})
        return hasError
    }

    render() {
        const {classes, userState} = this.props
        const {email, password} = this.state.fields
        const {hasError} = this.state
        return (
            <div>
                    <Snackbar
                        className={classes.errorContainer}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={userState.errorMessage !== ''}>
                        <SnackbarContent
                            className={classes.errorMessage}
                            message={userState.errorMessage}
                        />
                    </Snackbar>
                    <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
                        <TextField
                            onChange={this.onChange}
                            required
                            id="email"
                            type="email"
                            inputProps={
                                {
                                    'data-type': 'email',
                                    'id': 'email'
                                }
                            } // MUI returns a text type for password fields??
                            label="Email"
                            margin="normal"
                            fullWidth
                            error={!email.isValid}
                            helperText={email.isValid ? '' : email.errorText}
                        />
                        <TextField
                            onChange={this.onChange}
                            required
                            inputProps={
                                {
                                    'data-type': 'password',
                                    'id': 'password'
                                }
                            } // MUI returns a text type for password fields??
                            id="password"
                            type="password"
                            label="Password"
                            margin="normal"
                            fullWidth
                            error={!password.isValid}
                            helperText={password.isValid ? '' : password.errorText}
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            color="primary"
                            disabled={hasError || userState.fetching}
                        >
                            { userState.fetching ?
                                <CircularProgress size={24}/> : "Sign In"
                            }

                        </Button>
                    </form>
            </div>

        );
    }
}

export default connect(
    state => ({
        userState: state.user
    })
)(withStyles(styles)(LoginForm))
