import {actions} from './actions'

let initialState = {
    currentUser: null,
    errorMessage: '',
    fetching: false,

}

export default function reducer(state = initialState, action) {
    const {payload, type} = action

    // eslint-disable-next-line
    switch (type) {

        case actions.fetch:
            return {
                ...state, fetching: true, errorMessage: ''
            }

        case actions.fetchDone:
            return {
                ...state, fetching: false
            }

        case actions.signIn:
            return {
                ...state, errorMessage: ''
            };

        case actions.signOut:
            return {
            ...state, errorMessage: ''
        };

        case actions.signInSuccess:
            return {
                ...state, currentUser: payload
            }

        case actions.signInError:
            return {
                ...state, errorMessage: payload, currentUser: null
            }

    }
    return state;
}