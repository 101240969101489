import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/root.css'

import * as serviceWorker from './serviceWorker';

/** APP **/
import { apiInstance } from './services/index'


/** LAYOUTS **/
import App from './App';


/** INIT SERVICES **/
apiInstance.init(
    'https://api.plana.com.au/api/v1/',
    'zn1p0qqfhBzhfWtOQARbBLCPVtqqpf'
)


const rootEl = document.getElementById("root");

/** RENDER Main APP **/
ReactDOM.render(
    <App/>,
    rootEl
);

/** Allow redux state retention with HMR */
if (module.hot) {
    module.hot.accept("./App", () => {
        const NextApp = require("./App").default;
        ReactDOM.render(<NextApp />, rootEl);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
