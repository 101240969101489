import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/styles'
import {NavLink} from 'react-router-dom'
import classNames from 'classnames'
import {Tooltip} from "@material-ui/core";
import Zoom from '@material-ui/core/Zoom';

const style = theme => ({
    root: {},
    navLink: {
        color: theme.palette.grey[600],
        '&:hover': {
            color: theme.palette.secondary[300]
        },
        marginLeft: theme.spacing(2)
    },
    active: {
        color: theme.palette.primary[300],
    },
    disabledLink: {
        color: theme.palette.grey[300],
        pointerEvents: 'none'
    },
    tooltipPlacementRight: {
        margin: '0px'
    }
})

class HeaderMenu extends React.Component{

    createNav = routes => {
        const {classes} = this.props

        let el = <nav>
            {
                routes.map((item, i) => {
                    if(false === item.locations.includes('main')){ return ''}
                    return (
                        <Tooltip key={i} TransitionComponent={Zoom} title={item.label} placement="bottom">
                            <NavLink
                                className={classNames({
                                    [classes.navLink]: true,
                                    [classes.disabledLink]: item.disabled
                                })}
                                to={item.route}
                                activeClassName={classNames(classes.active)}
                            >
                                { item.label }
                            </NavLink>
                        </Tooltip>
                    )
                })
            }
        </nav>

        return el
    }

    render() {
        const {routes, classes} = this.props
        return (
            <div className={classes.root}>
                {this.createNav(routes)}
            </div>
        )

    }
}

HeaderMenu.defaultProps = {
    routes: []
}

HeaderMenu.propTypes = {
    routes: PropTypes.array.isRequired
}

export default withStyles(style)(HeaderMenu)