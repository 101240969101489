import {actions} from './actions'

let initialState = {
    columnsDescriptors: [],
    columnsFetching: false,
    collection: {
        page: 0,
        recordsPerPage: 0,
        totalRecordCount : 0,
        totalPageCount: 0,
        sortString: '',
        recordsetCount: 0,
        recordset: [],


    },
    collectionFetching: false,
    errorMessage: ''
}

export default function reducer(state = initialState, action) {
    const {payload, type} = action

    // eslint-disable-next-line
    switch (type) {

        /**** COLUMNS **/
        case actions.columnsFetch:
            return {
                ...state,  collectionFetching: true , errorMessage: ''
            }

        case actions.columnsFetchDone:
            return {
                ...state, columnsDescriptors: payload
            }

        case actions.columnsFetchError:
            return {
                ...state, errorMessage: payload
            }

        case actions.columnsFetchFinal:
            return {
                ...state, columnsFetching: false
            }

        /**** COLLECTION **/
        case actions.collectionFetch:
            return {
                ...state,  collectionFetching: true , errorMessage: ''
            }

        case actions.collectionFetchDone:
            return {
                ...state, collection: {...payload}
            }

        case actions.collectionFetchError:
            return {
                ...state, errorMessage: payload
            }

        case actions.collectionFetchFinal:
            return {
                ...state, collectionFetching: false
            }
    }
    return state;
}